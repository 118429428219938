<template>
  <div id="uslov" class="align">
    <div class="sec_5 contentBlock">
      <div class="title">
        <h3>Условия и документы</h3>
      </div>
      <div>
        <div id="menu">
          <div v-on:click="selectBlock(0)" v-bind:class="{active:block_num === 0}">Требования к заёмщику</div>
          <div v-on:click="selectBlock(1)" v-bind:class="{active:block_num === 1}">Условия выдачи займа</div>
<!--          <div v-on:click="selectBlock(2)" v-bind:class="{active:block_num === 2}">Способы погашения</div>-->
          <div v-on:click="selectBlock(3)" v-bind:class="{active:block_num === 3}">Свидетельства</div>
        </div>

        <div class="slid_1" v-bind:class="{ active: block_num === 0 }">
          Получить микрозайм может любой гражданин РФ от 18 до 70 лет, который проживает
          <a href="pdf/География присутствия.pdf">в зоне обслуживания</a>
          нашей компании. Необходимо наличие стабильного источника дохода, из документов нужен только паспорт.
        </div>

        <div class="slid_2" v-bind:class="{ active: block_num === 1 }">
          <h3>Мы полностью соблюдаем законодательство РФ. Со всеми документами вы можете ознакомиться заранее:</h3>
          <div>
            <p>
              <a target="_blank" href="https://finmoll.ru/backend/content/documents/rules_microloans">Правила
                предоставления микрозаймов
              </a>
            </p>
            <p>
              <a target="_blank" href="https://finmoll.ru/backend/content/documents/microloan_terms_conditions">Общие
                условия договора микрозайма
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="https://finmoll.ru/backend/content/documents/microloan_providing_info">Информация об условиях предоставления, использования и возврата потребительского займа
              </a>
            </p>
            <p>
              <a target="_blank"
                 href="pdf/informaciyaDlyaPoluchatelejFinansovojUslugi.pdf">Информация для получателей финансовой услуги
              </a>
            </p>
          </div>
        </div>



        <div class="slid_4" v-bind:class="{ active: block_num === 3 }">
          <ul>
            <li><a target="_blank" href="https://finmoll.ru/backend/content/documents/certificate_ooo_finmoll">Свидетельство ООО
              МКК
              «ФИНМОЛЛ»</a></li>
            <li><a target="_blank" href="https://finmoll.ru/backend/content/documents/certificate_sro_mir">Свидетельство
              членства СРО МИР</a></li>
          </ul>
        </div>
      </div>
      <br style="clear: both;" />
    </div>
  </div>
</template>

<script>
export default {
  name: "conditions",
  data() {
    return {
      block_num: 0
    }
  },
  methods: {
    selectBlock(blockNum) {
      this.block_num = blockNum;
    }
  }
}
</script>

<style scoped>
  span { font-family: 'Lato'; font-weight: bold }
  .sec_5 {}
  .sec_5 > h2 { margin-bottom: 9.97vw }
  .slid_1 { color: rgba(19,21,21,.7019607843137254); line-height: 150%; font-weight: 500; margin-top: 6.93vw; padding: 6.93vw 0; display: none }
  .slid_2 { margin-top: 6.93vw; padding: 6.93vw 4.27vw; display: none }
  .slid_2 > h3 { line-height: 5.60vw }
  .slid_2 > div > p > a { color: rgba(19,21,21,.7019607843137254); font-weight: 500 }
  .slid_3 { margin-top: 6.93vw; padding: 6.93vw 4.27vw; display: none; line-height: 150% }
  .slid_3 > h3 { line-height: 5.60vw }
  .slid_3 > p > span { color: #0d5740; }
  .slid_3 > p { padding: 3.47vw 0 }
  .num { width: 4.27vw; height: 4.27vw; line-height: 4.2vw; padding: 0.73vw; background: #45b537; border-radius: 6.93vw; color: #fff; display: inline-block; text-align: center;}
  .slid_3 > div > div > b { line-height: 13.87vw }
  .slid_3 > div > div > p { }
  .slid_3 > div > div > p > a { color: rgba(19,21,21,.7019607843137254) }
  .slid_3 > div > div > div > p {  padding-top: 5.60vw  }
  .slid_3 > div > div > div > img { margin: 4.27vw}
  .slid_3 > div > div > p > span { color: #131515; padding-right: 2vw }
  .slid_3 > div > div > .requisites > p > span { color: #131515; padding-right: 2vw; font-size: 3.53vw; font-family: 'Lato'; font-weight: bold }
  .slid_3 > div > div > .requisites > p { }
  .slid_3 > div > div > .requisites > div { padding-top: 5.60vw }
  .slid_3 > div > div > .requisites > div > img { margin: 4.27vw }
  .slid_3 > div > div > .requisites > b { line-height: 13.87vw }
  .slid_3 > div > div > .requisites > div > p { }
  .slid_3 > div > div > .requisites > div > p > span { color: #131515; padding-right: 2vw; font-size: 3.53vw; font-family: 'Lato'; font-weight: bold }
  .requisitesText { display: flex }
  .requisitesText > div { display: flex; flex-direction: column; padding:0.80vw 2vw 0 0 }
  .requisitesText > div > span { color: #131515; white-space: nowrap }
  .requisitesText > div > p { }
  .misc { padding: 3.47vw 0}
  .slid_4 { margin-top: 6.93vw; padding: 6.93vw 4.27vw; display: none }
  .slid_4 > h3 { }
  .sec_5 > div {}
  .sec_5 > div > div { font-size: 4.12vw; }
  .hidden { visibility: hidden; display: none; }
  .active { display: block }
  #menu { display:flex; overflow-y: scroll; border: unset }
  #menu > div { padding: 2.57vw 6.83vw; border-right: 1px solid rgba(0,0,0,.2); text-align: unset; white-space: nowrap; font-size: 2.94vw }
  #menu > div.active{color: #45B537FF; font-family: 'Lato'; font-size: 3.53vw; font-weight: bold }
  .mts { width: 15vw; height: 15vw }
  .qiwi { width: 38.82vw }
  @media (min-width: 760px) {
  p { font-size: 0.97vw }
  span { font-size: 0.97vw }
  b { font-size: 1.13vw }

  .sec_5 { }
  .sec_5 > h2 { margin-bottom: 2.50vw; text-align: center;  }
  .sec_5 > div { display: flex }
  .sec_5 > div > div { font-size: 1.45vw }
  #menu { flex-direction: column; overflow-y: unset;  }
  #menu > div { padding: 1.29vw 7.74vw; text-align: center; border: 1px solid #E4E4E4; transition: ease-out 0.3s; cursor: pointer; font-size: 1.21vw}
  #menu > div.active { font-size: 1.21vw}

  .slid_1 { line-height: 150%; margin-top: 0; padding: 4.11vw 2vw; margin-left: 3.55vw; }
  .slid_1 > a { color: #43AC34; }
  .slid_2 { line-height: 150%; margin-top: 0; padding: 4.11vw 2vw; margin-left: 3.55vw }
  .slid_2 > h3 { line-height: 150% }
  .slid_2 > div > p > a { font-size: 1.45vw; color: #43AC34; }
  .slid_2 > a { color: #43AC34; }
  .slid_3 { line-height: 150%; margin-top: 0; padding: 4.11vw 2vw; margin-left: 3.55vw }
  .slid_3 > h3 { line-height: 150% }
  .slid_3 > p { line-height: 150%; padding: 0.48vw }
  .slid_3 > p > span {  }
  .slid_3 > div > div > b { line-height: 150% }
  .slid_3 > div > div > p { padding: 0.48vw }
  .slid_3 > div > div > p > a { font-size: 1.45vw; }
  .slid_3 > div > div > div > p {  padding-top: 0.73vw }
  .slid_3 > div > div > div > img { margin: 0.56vw}
  .slid_3 > div > div > p > span { padding-right: 2vw }
  .slid_3 > div > div > .requisites > p { }
  .slid_3 > div > div > .requisites > p > span { padding-right: 1vw; font-size: 0.97vw }
  .slid_3 > div > div > .requisites > div { padding-top: 0.73vw }
  .slid_3 > div > div > .requisites > div > img { margin: 0.56vw }
  .slid_3 > div > div > .requisites > b { line-height: 150% }
  .slid_3 > div > div > .requisites > div > p { padding-right: 2vw }
  .slid_3 > div > div > .requisites > div > p > span { padding-right: 1vw; font-size: 0.97vw }
  .num { width: 0.89vw; height: 0.89vw; line-height: 150%; padding: 0.32vw; border-radius: 11px; display: inline-block; margin-right: 0.89vw }
  .requisitesText > div { display: flex; flex-direction: column; padding:0.80vw 2vw 0 0 }
  .requisitesText > div > span { }
  .requisitesText > div > p { }
  .slid_4 { line-height: 150%; margin-top: 0; padding: 4.11vw 2vw; margin-left: 3.55vw }
  .slid_4 > h3 { line-height: 150% }
  #uslov { margin-bottom: 16.13vw }
  #slid_1:checked + div, #slid_2:checked + div, #slid_3:checked + div, #slid_4:checked + div { background: #E7F3E5; }
  .mts { width: 5vw; height: 5vw }
  .qiwi { width: 10.65vw; height: 4vw }
}
  @media (min-width: 1240px) {
  a { font-size: 14px }
  p { font-size: 12px }
  span { font-size: 12px }
  b { font-size: 14px }
  .sec_5 {}
  .sec_5 > h2 { margin-bottom: 31px }
  .sec_5 > div {  }
  .sec_5 > div > div { font-size: 18px }
  #menu {  }
  #menu > div { padding: 16px 95px; font-size: 15px }
  #menu > div.active { font-size: 15px }

  .slid_1 { padding: 51px 0; margin-left: 64px }
  .slid_1 > a { font-size: 18px }
  .slid_2 { padding: 51px 0; margin-left: 64px }
  .slid_2 > h3 { }
  .slid_2 > div > p > a { font-size: 18px }
  .slid_2 > a { }
  .slid_3 { padding: 51px 0; margin-left: 64px }
  .slid_3 > h3 { }
  .slid_3 > p { padding: 9px }
  .slid_3 > p > span { }
  .slid_3 > div > div > b { }
  .slid_3 > div > div > p { padding: 0 }
  .slid_3 > div > div > p > a { font-size: 12px }
  .slid_3 > div > div > div > p {  padding-top: 0.73vw }
  .slid_3 > div > div > div > img { margin: 10px}
  .slid_3 > div > div > p > span { padding-right: 40px }
  .slid_3 > div > div > .requisites > p { }
  .slid_3 > div > div > .requisites > p > span { padding-right: 10px; font-size: 12px }
  .slid_3 > div > div > .requisites > div { padding-top: 10px }
  .slid_3 > div > div > .requisites > div > img { margin: 10px }
  .slid_3 > div > div > .requisites > b { }
  .slid_3 > div > div > .requisites > div > p { padding-right: 40px }
  .slid_3 > div > div > .requisites > div > p > span { padding-right: 10px; font-size: 12px }
  .num { width: 24px; height: 24px; padding: unset; border-radius: 19px; margin-right: 17px  }
  .requisitesText > div { padding:15px 40px 0 0 }
  .requisitesText > div > span { }
  .requisitesText > div > p { }
  .slid_4 { padding: 51px 0; margin-left: 64px }
  .slid_4 > h3 { }
  #uslov { margin-bottom: 200px }
  #slid_1:checked + div, #slid_2:checked + div, #slid_3:checked + div, #slid_4:checked + div { }
  .mts { width: 50px; height: 50px }
  .qiwi { width: 132px; height: 50px }
}
</style>